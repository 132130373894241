.course_payment_tab {
  padding: 15rem 0;
  width: calc(100% - 40px);
  margin: auto;
  text-align: center;

  .course_tab {
    h2 {
      // font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
      margin-bottom: 60px;
    }

    .steps {
      ul {
        @extend .d_flex;
        @extend .align_items_center;
        @extend .justify_content_around;
        border-bottom: 1px solid #c4c4c4;
        padding-bottom: 30px;
        @media (max-width: $phone) {
          align-items: flex-start;
        }

        li {
          // font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          color: #9e9e9e;
          cursor: pointer;
          display: flex;
          align-items: center;
          @media (max-width: $ipad) {
            flex-direction: column;
          }
          @media (max-width: $phone) {
            font-size: 18px;
            line-height: 22px;
            width: calc(33.33% - 30px);
            margin: 15px;
          }
          span {
            padding: 1px 7.5px;
            background: #d9d9d9;
            border-radius: 100%;
            color: #000;
            margin-right: 10px;
            @media (max-width: $ipad) {
              margin-bottom: 10px;
            }
          }

          &.active {
            font-weight: 600;
            color: #000000;

            span {
              background: #f7ce46;
              color: #000000;
            }
          }
        }
      }
    }

    .course_registration_tab {
      padding: 70px 0;

      .course_registration {
        form {
          width: 100%;
          max-width: 820px;
          margin: auto;

          h5 {
            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #000000;
            margin-bottom: 70px;
            text-transform: unset;
          }

          .form_group {
            width: 100%;
            @extend .d_flex_center;

            label {
              width: 35%;
              // font-family: 'Roboto';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
              color: #000000;
              text-align: right;
              margin-bottom: 10px;
            }

            .form_content {
              width: 70%;
              text-align: left;
              margin-left: 41px;

              p {
                // font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: #000000;
                margin-bottom: 10px;
              }

              .form_control {
                max-width: 100%;
                padding: 1.2rem 1rem;
              }

              .btn_primary {
                min-width: 21rem;
                padding: 1.66rem 1.5rem;
                margin-top: 60px;
              }
            }
          }
        }

        &.course_payment {
          form {
            width: 100%;
            max-width: 595px;
            margin: auto;
            text-align: left;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            h4 {
              // font-family: 'Roboto';
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 21px;
              color: #000000;
              margin-bottom: 34px;
              text-transform: unset;
            }

            .form_group {
              label {
                text-align: left;
                margin-bottom: 10px;
              }
            }

            .pay_info {
              width: 100%;
              max-width: 56.1rem;
              margin-bottom: 30px;

              h4 {
                text-align: left;
                margin-bottom: 15px;
              }

              .form_group {
                justify-content: flex-start;

                label {
                  text-align: left;
                  margin-bottom: 10px;
                }

                .form_control {
                  max-width: 48rem;
                }
              }

              .card_details {
                max-width: 48rem;
                justify-content: space-between;

                .form_group {
                  max-width: 20rem;
                }
              }

              &.billing_info {
                h4 {
                  text-align: left;
                  margin-bottom: 15px;
                }

                .form_group {
                  justify-content: flex-start;
                  flex-direction: column;

                  label {
                    width: 100%;
                    text-align: left;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    text-transform: capitalize;
                    color: #494949;
                    margin-bottom: 10px;
                  }

                  .form_control {
                    max-width: 100%;
                  }
                }

                .billing_details {
                  max-width: 100%;
                  justify-content: space-between;

                  .form_group {
                    max-width: 26.1rem;
                    flex-direction: column;

                    label {
                      width: 100%;
                      text-align: left;
                      font-weight: 400;
                      font-size: 15px;
                      line-height: 18px;
                      text-transform: capitalize;
                      color: #494949;
                      margin-bottom: 10px;
                    }
                  }
                }

                .billing_location {
                  @extend .d_flex_center;
                  justify-content: space-between;

                  .form_group {
                    max-width: 18rem;
                  }
                }
              }
            }
          }
        }

        &.account_create {
          form {
            width: 100%;
            max-width: 26.1rem;
            margin: auto;
            text-align: left;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            .form_group {
              flex-direction: column;
              align-items: flex-start;

              &.btn_send {
                flex-direction: unset;
                justify-content: flex-start;
              }

              label {
                width: 100%;
                text-align: left;
                @extend .national_regular;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                text-transform: capitalize;
                color: #494949;
                margin-bottom: 10px;
              }

              .form_control {
                max-width: 48rem;
                padding: 0.75rem 1rem;
                padding-right: 3rem;
              }
            }

            .password {
              width: 100%;
              max-width: 20rem;

              .position_relative {
                width: 100%;

                span.grideye {
                  @extend .d_flex_center;
                  @extend .position_absolute;
                  @extend .cursor_pointer;
                  width: 2rem;
                  height: 2rem;
                  right: 0.9rem;
                  top: 0.8rem;
                }
              }

              .pass_content {
                @extend .national_light;
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
                display: flex;
                align-items: center;
                letter-spacing: -0.288px;
                color: #000000;
                padding: 5px 0;
              }

              p.error_msg {
                @extend .national_regular;
                font-size: 10px;
                line-height: 12px;
                display: flex;
                align-items: center;
                letter-spacing: -0.288px;
                color: #990000;
                padding: 5px 0;
                display: none;
              }
            }

            p.agree_policy {
              @extend .national_regular;
              font-weight: 400;
              font-size: 10px;
              line-height: 12px;
              display: flex;
              align-items: center;
              letter-spacing: -0.288px;
              color: #292929;
              flex-direction: column;

              a {
                font-weight: 600;
                width: 100%;
                display: block;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .ant-form {
      width: 100%;
      //max-width: 820px;
      margin: auto;
      text-align: left;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 70px 0;

      .applocant_info {
        width: 100%;
        justify-content: center;
        padding: 0 150px;
        @extend .d_flex;
        @extend .align_items_start;
        @extend .justify_content_center;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1200px) {
          padding: 0 70px;
        }
        @media (max-width: $ipad) {
          flex-direction: column;
          padding: 0;
        }

        h4 {
          text-align: left;
          margin-bottom: 27px;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: #000000;
          text-transform: capitalize;
        }

        h3 {
          width: 100%;
          margin: 30px;
          margin: 30px 0;
          text-align: left;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: #000000;
          text-transform: capitalize;
          @media (max-width: $ipad) {
            width: 100%;
            margin: 10px 0;
          }
        }

        .form_fill_content {
          //width: calc(70% - 60px);
          max-width: 545px;
          width: 100%;
          margin: 27px 0;
          @media (max-width: $ipad) {
            width: 100%;
            max-width: 100%;
          }
          @media (max-width: $phone) {
            margin: 0 0;
          }
          .dec_more {
            margin-bottom: 10px;
          }
          .main_title {
            font-weight: bold;
            font-size: 17px;
            margin-bottom: 8px;
          }
          .form_group label {
            display: block;
            margin-bottom: 10px;
          }

          .form_group .form_control {
            max-width: 100%;
          }

          .ant-form-item {
            .ant-row {
              flex-direction: column;

              .ant-form-item-label {
                overflow-wrap: break-word;
                text-align: left;
                white-space: normal;
                label {
                  height: 100%;
                  margin-bottom: 10px;
                  align-items: flex-start;
                  &:after {
                    content: none;
                  }
                }
              }

              .ant-col {
                .ant-form-item-control-input {
                  .ant-form-item-control-input-content {
                    .date-input {
                      padding: 11px 19px;
                    }

                    .ant-input {
                      padding: 11px 19px;
                    }
                    .ant-radio-group {
                      display: flex;
                      flex-direction: column;
                      align-items: baseline;
                    }
                    .ant-checkbox-group {
                      display: flex;
                      flex-direction: column;
                      align-items: baseline;
                      label {
                        margin-left: 8px;
                      }
                    }
                  }
                }
              }
            }
            .ant-select-selector {
              background: #f5f6f5;
              border-radius: 5px;
              border: none;
              padding: 7px 19px;
              height: 100%;
              // .ant-select-selection-placeholder {
              //   padding: 0px 19px;
              // }
              .ant-select-selection-search {
                padding: 7px 7px;
              }
              .ant-select-selection-item {
                color: #4a4a4a;
              }
            }

            .ant-select-multiple {
              .ant-select-selector {
                background: #f5f6f5;
                border-radius: 5px;
                border: none;
                padding: 7px 19px;
                height: 100%;
                .ant-select-selection-placeholder {
                  padding: 7px 19px;
                }
              }
            }

            .ant-picker {
              background: #f5f6f5;
              border-radius: 5px;
              border: none;
              width: 100%;
              outline: unset !important;
              box-shadow: unset !important;
              padding: 11px 19px;
            }
          }
          &.dl_program_btns {
            display: flex;
            justify-content: center;
            gap: 34px;
          }
        }
      }

      .btn_primary {
        @extend .d_flex_center;
        width: 100%;
        max-width: 120px;
        margin-left: auto;
        margin-right: 30px;

        &.dl_continue {
          background: #f7ce46;
          color: #000;
          margin: 0;
          background: #000;
          color: #fff;
          &:hover {
            background: #000;
            color: #fff;
          }
          &.undefined{
            background: $black;
        }
        }
      }
    }
  }
}

.course-information {
  max-width: 545px;
  .ant-form-item {
    .ant-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .ant-form-item-label {
        overflow-wrap: break-word;
        text-align: left;
        white-space: normal;
        label {
          height: 100%;
          align-items: flex-start;
          &:after {
            content: none;
          }
        }
      }

      .ant-form-item-control {
        width: 100%;
        max-width: 545px;
        .ant-select-selector {
          background: #f5f6f5;
          border-radius: 5px;
          border: none;
          padding: 7px 19px;
          height: 100%;
          // .ant-select-selection-placeholder {
          //   padding: 0px 19px;
          // }
        }
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .upload_wrapper {
              display: flex;
              width: 100%;
              align-items: flex-end;
              column-gap: 12px;
              span {
                width: 100%;
              }
              .del-icon {
                margin-bottom: 5px;
                cursor: pointer;
              }
            }
            textarea {
              background: #f5f6f5;
              border: none;
              border-radius: 7px;
            }
            span {
              .ant-upload {
                width: 100%;
                height: 100%;
                img {
                  height: 100%;
                  width: 100%;
                  max-height: 70px;
                  object-fit: cover;
                  border-radius: 7px;
                  border: 1px solid #e2e2e2;
                  cursor: pointer;
                }
                button {
                  width: 100%;
                  height: 70px;
                  background: #f5f6f5;
                  border-radius: 7px;
                  max-width: 523px;
                  span {
                    svg {
                      fill: #c1c1c1;
                      width: 22px;
                      height: 22px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.course_tab {
  .course-information {
    max-width: 100%;
  }
}

.course_payment {
  padding: 100px 10px;
  width: 100%;
  max-width: 110rem;
  margin: auto;
  p {
    margin-top: 12px;
    text-align: center;
  }
  button {
    display: flex;
    align-items: center;
    margin: 5rem auto;
    min-width: 20rem;
    font-size: 1.7rem;
    line-height: 2rem;
    justify-content: center;
  }
  .dl_comp_payment {
    width: 100%;
    line-height: 24px;
    font-size: 2rem;
  }

  .program_coming_soone{
    font-size: 3rem;
    font-weight: 700;
    font-family: sans-serif;
    font-size: 3rem;
    margin-bottom: 20px;

  }
  .congratulations_page_mail {
    margin-left: 5px;
  }
  .payment_option{
    display: flex;
    justify-content: center;
    gap: 50px; 
    button{
      margin: 5rem 0;
    }

    @media (max-width: $phone) {
      display:block;

      button{
        margin: 5rem auto;
      }
    }

    
  }
}

.success-screen {
  width: 100%;
  h3 {
    text-align: center;
    margin-top: 82px;
    margin-bottom: 55px;
  }
  p {
    text-align: center;
  }

  &_loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }
}

.post-loader {
  background: #f6f6f6;
  height: 70px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 523px;
  border: 1px solid #e2e2e2;
}

.image-placeholder {
  background: #f6f6f6;
  height: 70px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: 523px;
  border: 1px solid #e2e2e2;
}

.doc-placeholder {
  width: 100%;
  text-align: center;
  span {
    font-weight: bold;
    cursor: pointer;
  }
}

.dl_continue {
  background: #f7ce46;
  color: #000;
  background: #000;
  color: #fff;
  // margin: 0;
  // margin-left: 1rem;
  &:hover {
    background: #000;
    color: #fff;
  }
}
.titleRefresnce{
  @extend .national_semibold;
  font-size: 15px;
  line-height: 30px;
}
.status_program{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh ;
}
.number_span{
  display: block;
  margin: 0 3px;
  margin-top: -4px;
}

// .number_span {
//   margin: 0 3px;
//   display: inline-block;
//   vertical-align: middle;
// }

.num_pen{
  margin: 0 3px;
}

.num_emi{
  font-size: 20px;
 
}
.emi_btn{
  align-items: baseline !important;
}