.main_section {
    min-height: calc(100vh - 184px);

    .Terms_Use {
      
        .container {
            max-width: 930px;
            padding: 55px 15px 59px;
        }

        .terms_content {
            h1.trem_heading {
                font-size: 26px;
                line-height: 20px;
                padding: 30px 0;
                margin-bottom: 30px;
            }

            h2.Terms_title {
                text-transform:none;
                font-size: 24px;
                line-height: 10px;
                margin-bottom: 15px;
            }

            .Terms_description {
                margin-bottom: 40px;

                p {
                    font-size: 15px;
                    line-height: 20px;
                    color: #0C0C0C;
                    margin-bottom: 15px;
                }

                p span.bold {
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 34px;
                    color: #0C0C0C;
                    margin-bottom: 15px;
                }

                p a {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    color: blue;
                    text-decoration: underline;
                    margin: 0 3px;
                    text-transform: capitalize;
                }

                ul {
                    padding-left: 80px;
                    list-style: none;
                }

                ul li {
                    font-size: 15px;
                    line-height: 24px;
                    margin-bottom: 15px;
                    position: relative;
                }
                .congratulations_page_mail{
                    text-decoration: none;
                    cursor: pointer;
                    color: #0C0C0C;

                    &:hover{
                        color:  #f7ce46;
                    }
                }
                // ul li::after {
                //     content: '';
                //     display: block;
                //     width: 7px;
                //     height: 7px;
                //     background: #900;
                //     border-radius: 100%;
                //     position: absolute;
                //     left: -20px;
                //     top: 8px;
                // }
            }
        }
    }
}