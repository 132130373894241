.homepage_sec {
  .home_banner {
    position: relative;
    height: 349px;
    background: #000000;

    img {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      // object-fit: cover;
      object-position: center;
      height: 349px;
      display: block;

      @media (max-width: $phone_landscape) {
        min-height: 270px;
        object-fit: cover;
      }

      @media (max-width: $iphone_ii) {
        object-position: -306px 0;
      }
    }

    .banner_text {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: rgba(153, 0, 0, 0.8);
      width: 36%;
      padding: 0 40px;
      @extend .d_flex;
      @extend .align_items_center;
      @extend .justify_content_end;

      @media (max-width: $ipadPro) {
        padding: 0 15px;
      }

      @media (max-width: $ipad_landscape) {
        padding: 0 10px;
      }

      @media (max-width: $phone_landscape) {
        padding: 0 20px;
        width: 62%;
      }

      @media (max-width: $phone) {
        padding: 0 30px;
        width: 78%;
      }

      p {
        @extend .national_semibold;
        font-size: 25px;
        line-height: 30px;
        @extend .text_right;
        @extend .text_uppercase;
        color: $white;
        max-width: 355px;

        @media (max-width: $desktop_sm) {
          font-size: 20px;
        }

        @media (max-width: $ipadPro) {
          font-size: 20px;
        }

        @media (max-width: $ipad_landscape) {
          font-size: 18px;
          max-width: 250px;
        }

        @media (max-width: $phone_landscape) {
          font-size: 25px;
          padding: 7px 0;
          line-height: 30px;
          text-align: right;
          max-width: 100%;
        }

        @media (max-width: $phone) {
          text-align: left;
        }
      }
    }
    .banner_text_img {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      max-width: 1160px;
      padding: 0 15px 120px;
      right: 0;
      width: 100%;
      @media (max-width: $phone_landscape) {
        padding: 0 15px 60px;
      }

      @media (max-width: $phone_landscape) {
        min-height: 270px;
        object-fit: cover;
      }

      @media (max-width: $iphone_ii) {
        object-position: -306px 0;
      }
    }
    .banner_text_img {
      h1 {
        @extend .national_semibold;
        font-size: 54px;
        line-height: 78px;
        color: #ffffff;
        text-transform: uppercase;
        @media (max-width: $phone) {
          font-size: 40px;
          line-height: 60px;
        }
        @media (max-width: $iphone_ii) {
          font-size: 30px;
          line-height: 38px;
        }
        span {
          display: block;
          padding-top: 18px;
          font-size: 28px;
          line-height: 34px;
          color: #cccccc;
          @media (max-width: $phone) {
            font-size: 24px;
            line-height: 40px;
          }
          @media (max-width: $iphone_ii) {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
    &.certificate {
      img {
        object-fit: cover;
      }
    }
  }

  .home_course_sec {
    max-width: 890px;
    @extend .mx_auto;
    padding: 96px 15px 70px;

    @media (max-width: $phone_landscape) {
      padding: 50px 30px 55px;
    }

    @media (max-width: $iphone_ii) {
      padding: 50px 30px 55px;
    }

    h2.home_course_title {
      @extend .national_semibold;
      font-size: 32px;
      line-height: 38px;
      color: $black;
      margin: 0 0 35px;

      @media (max-width: $iphone_ii) {
        margin: 0 0 25px;
      }
    }

    .course_list {
      display: flex;
      flex-wrap: wrap;

      .course_box {
        position: relative;
        width: 47.6%;
        margin-right: 4.7%;
        margin-bottom: 70px;
        height: 294px;

        .course_message {
          color: #990000;
          margin-top: 10px;
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        @media (max-width: $ipad_landscape) {
          height: 255px;
        }

        @media (max-width: $phone_landscape) {
          width: 100%;
          margin: 0 auto 25px !important;
          height: 228px;
          max-width: 350px;
        }

        @media (max-width: $iphone_ii) {
          max-width: 315px;
          margin: 0 auto 20px !important;
        }

        .course_wrap {
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .course_img {
          @include filter(drop-shadow(0px 0px 10px rgba(164, 164, 164, 0.75)));
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            clip-path: polygon(
              7% 0,
              93% 0,
              100% 8%,
              100% 92%,
              93% 100%,
              7% 100%,
              0 92%,
              0 8%
            );
            display: block;
            object-fit: cover;
          }
        }

        .course_detail {
          position: absolute;
          top: 0;
          left: -1px;
          bottom: -0.5px;
          width: 51%;
          background: rgba(255, 255, 255, 0.9);
          clip-path: polygon(
            14% 0,
            100% 0,
            100% 10%,
            100% 94%,
            100% 100%,
            14% 100%,
            0 92%,
            0 8%
          );
          padding: 45px 23px 0 28px;

          @media (max-width: 900px) {
            padding: 45px 15px 0 15px;
          }

          @media (max-width: $ipad) {
            padding: 30px 18px 0 18px;
          }

          @media (max-width: $phone_landscape) {
            padding: 30px 18px 0 18px;
            clip-path: polygon(
              12% 0,
              100% 0,
              100% 10%,
              100% 94%,
              100% 100%,
              12% 100%,
              0 92%,
              0 8%
            );
          }

          @media (max-width: $iphone_ii) {
            width: 59%;
          }

          h2 {
            @extend .national_semibold;
            font-size: 18px;
            line-height: 20px;
            @extend .text_uppercase;
            color: $maroon;
            margin: 0 0 9px;
            height: 8rem;
            @include multiline_ellipsis(4);

            @media (max-width: 900px) {
              max-width: 158px;
            }

            @media (max-width: $ipad) {
              font-size: 18px;
            }

            @media (max-width: $iphone_ii) {
              height: 100px;
              @include multiline_ellipsis(5);
            }
          }

          span {
            @extend .national_regular;
            font-size: 14px;
            line-height: 17px;
            color: $black;
            margin: 0 0 58px;
            display: block;

            @media (max-width: 900px) {
              margin: 0 0 35px;
            }

            @media (max-width: $ipad) {
              margin: 0 0 25px;
            }

            @media (max-width: $phone_landscape) {
              margin: 0 0 20px;
            }
          }

          .yellow_btn {
            margin: 0 0 20px;

            @media (max-width: $phone) {
              height: 25px;
              line-height: 25px;
            }
          }
        }

        .disabled_course_detail {
          position: absolute;
          top: 0;
          left: -1px;
          bottom: -0.5px;
          width: 51%;
          background: #dddddd;
          clip-path: polygon(
            14% 0,
            100% 0,
            100% 10%,
            100% 94%,
            100% 100%,
            14% 100%,
            0 92%,
            0 8%
          );
          padding: 45px 23px 0 28px;

          button {
            cursor: not-allowed;
          }

          @media (max-width: 900px) {
            padding: 45px 15px 0 15px;
          }

          @media (max-width: $ipad) {
            padding: 30px 18px 0 18px;
          }

          @media (max-width: $phone_landscape) {
            padding: 30px 18px 0 18px;
            clip-path: polygon(
              12% 0,
              100% 0,
              100% 10%,
              100% 94%,
              100% 100%,
              12% 100%,
              0 92%,
              0 8%
            );
          }

          @media (max-width: $iphone_ii) {
            width: 59%;
          }

          h2 {
            @extend .national_semibold;
            font-size: 18px;
            line-height: 20px;
            @extend .text_uppercase;
            color: $maroon;
            margin: 0 0 9px;
            height: 8rem;
            @include multiline_ellipsis(4);

            @media (max-width: 900px) {
              max-width: 158px;
            }

            @media (max-width: $ipad) {
              font-size: 18px;
            }

            @media (max-width: $iphone_ii) {
              height: 100px;
              @include multiline_ellipsis(5);
            }
          }

          span {
            @extend .national_regular;
            font-size: 14px;
            line-height: 17px;
            color: $black;
            margin: 0 0 58px;
            display: block;

            @media (max-width: 900px) {
              margin: 0 0 35px;
            }

            @media (max-width: $ipad) {
              margin: 0 0 25px;
            }

            @media (max-width: $phone_landscape) {
              margin: 0 0 20px;
            }
          }

          .yellow_btn {
            margin: 0 0 20px;

            @media (max-width: $phone) {
              height: 25px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}

.course_wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  .course_status_msg {
  }
}
