.event_calanderview{
   
}
.ant-modal-content{
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    #pdf-content{
        .calander_btn{
            border:0;
            background: #2c3e50;
            padding: 6px 10px;
            border-radius: 8px;
            color: #fff;
            margin-right: 15px;
        }
    }
    p{
        margin-top: 10px;
        span{
            font-weight: 700;
            img{
                max-width: 25px;
                margin-right: 6px;
            }
        }
        .calander_pdf{
            background: #E8E6E6;
            padding: 5px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
        }
       
    }
    .pdf-content{
        p {
            margin-top: 0px;
        }
        .first-ele{
            margin-top: 10px;
        }
    }
    .calander_link{
        cursor: pointer;
        color: #4A65F5;
        font-weight: normal;
        span{
            font-weight: 400;
        }
    }
    .calander_link_normal{
        cursor: pointer;
    }
}
.ant-modal-wrap{
    .ant-modal{
        max-width: 400px;
        margin: auto;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
        .ant-modal-body{
            box-shadow: 0px 3px 20px 0px #00000080;
            border-radius: 12px;
            -webkit-border-radius: 12px;
            -moz-border-radius: 12px;
            -ms-border-radius: 12px;
            -o-border-radius: 12px;
        }
    }
}

.courseDetail_tab_wrap .course_tab_content .courseDetail_title{
    font-family: 'national_regular';

    &.old{
        font-family: 'national_semibold';
    }
}

.ant-modal.video_popup{
    max-width: 900px;
    border-radius: 0;
    .ant-modal-content{
        border-radius: 0;
        .ant-modal-body{
            border-radius: 0;
        }
    }
}
.ant-modal-content #pdf-content .calander_btn{
    background: #494949;
    color: #fff;
}
.ant-modal-content #pdf-content .calander_btn:hover{
    background: #FACD04;
    color: #000;
}
.event_calanderview .fc-direction-ltr .fc-button-group > .fc-button{
    background: #FACD04;
    border: 0;
}
.event_calanderview .fc-icon-chevron-right::before,
.event_calanderview .fc-icon-chevron-left::before {
    color: #000;
}
.event_calanderview .fc .fc-button-primary:disabled{
    background: #b7b7b7;
    color: #6b6b6b;
    border: 0;
}
.event_calanderview .fc .fc-button-primary{
    background: #494949;
    color: #fff;
}
.event_calanderview .fc-direction-ltr .fc-button-group > .fc-button.fc-dayGridMonth-button.fc-button-active,
.event_calanderview .fc-direction-ltr .fc-button-group > .fc-button.fc-timeGridWeek-button.fc-button-active,
.event_calanderview .fc-direction-ltr .fc-button-group > .fc-button.fc-timeGridDay-button.fc-button-active,
.event_calanderview .fc-direction-ltr .fc-button-group > .fc-button.fc-listMonth-button.fc-button-active{
    background: #494949;
    color: #fff;
}
.event_calanderview .fc-direction-ltr .fc-button-group > .fc-button.fc-dayGridMonth-button,
.event_calanderview .fc-direction-ltr .fc-button-group > .fc-button.fc-timeGridWeek-button,
.event_calanderview .fc-direction-ltr .fc-button-group > .fc-button.fc-timeGridDay-button,
.event_calanderview .fc-direction-ltr .fc-button-group > .fc-button.fc-listMonth-button{
    background: #B7B7B7;
    color: #6B6B6B;
}
.event_calanderview .fc-h-event{
    background: #FACD04;
    border: #FACD04;
    color: #191919;
}
.event_calanderview .fc-h-event .fc-event-main {
    color: #191919;
}
.event_calanderview .fc .fc-daygrid-day.fc-day-today {
    background-color: #fff;
}
.event_calanderview {
    background-color: #F2F2F2;
    padding: 20px 15px;
    // border-top: 4px solid #FACD04;
}
.event_calanderview .fc-day.fc-day-today.fc-timegrid-col{
    background: #fff;
}
.tools_content a{
    color: #000 !important;
}
.tools_content a:hover{
    color: #FACD04 !important;
}
.link_hover li span{
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
}
.link_hover li span:hover{
    color: #FACD04;
}
.rdw-link-decorator-icon{
    display: none !important;
}
.courseDetail_tab_wrap .course_faq_wrap ul.faq_course_list li .faq_con .DraftEditor-root a{
    color: #000;
}
.courseDetail_tab_wrap .course_faq_wrap ul.faq_course_list li .faq_con .DraftEditor-root a:hover{
    color: #FACD04;
}
.rdw-link-decorator-wrapper a span{
    color: #000;
}
.rdw-link-decorator-wrapper a span:hover{
    color: #FACD04;
}
.ant-modal-content .calander_link{
    color: #000;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
}
.ant-modal-content .calander_link:hover{
    color: #FACD04;
}
.ant-modal-content .calander_link_normal:hover{
color: #FACD04;
}
a:hover {
    color: #FACD04;
}
.ant-modal.errorPop{
    max-width: 450px;
}
.signin .container form .password {
    max-width: none;
}
.signin .container .signup_contain .signup_des{
    margin-bottom: 30px;
}
.signin .container .signup_contain .signup_signin_link{
    margin-bottom: 18px;
}
.course_payment_tab .course_tab .ant-form .btn_primary.dl_continue,
.dl_continue {
    background: #f7ce46;
    color: #000;
    margin: 0;
}
.mail_box .form_group.pt_1.pb_2{
    justify-content: center;
    display: flex;
    gap: 20px;
}
.fc-event{
     white-space: normal;
}
.fc-prev-button.fc-button.fc-button-primary:target{
    background: #000;
}
.event_calanderview .fc-direction-ltr .fc-button-group > .fc-button.fc-prev-button:focus,
.event_calanderview .fc-direction-ltr .fc-button-group > .fc-button.fc-next-button:focus{
    box-shadow: rgb(216 185 45) 0px 0px 0px 0.2rem;
}
.not-disbaled-download {
    width: fit-content;
    display: block;
    margin-left: auto;
    background: #494949;
    color: #fff;
    margin-bottom: 8px;
    padding: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.not-disbaled-download .tooltip{
    display: none;
}
.disbaled-download{
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-bottom: 8px;
    cursor: not-allowed;
    color: #8e8b8b !important;
    position: relative;
    background: #B7B7B7;
    color: #6B6B6B;
    padding: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-transform: translateZ(0);
    -webkit-font-smoothing: antialiased;
}
.disbaled-download .tooltip {
    background: #494949;
    bottom: 100%;
    color: #fff;
    display: block;
    left: 0;
    text-align: center;
    margin-bottom: 15px;
    opacity: 0;
    padding: 10px;
    pointer-events: none;
    position: absolute;
    width: 100%;
    border-radius: 5px;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.disbaled-download .tooltip:before {
    bottom: -20px;
    content: " ";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
}  
.disbaled-download .tooltip:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #494949 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
} 
.disbaled-download:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}
.disbaled-download .tooltip {
    display: none;
} 
.disbaled-download:hover .tooltip {
    display: block;
}
.slick-dots li button:before{
    font-size: 10px !important;
}
.courseDetail_tab_wrap_calander{
    max-width: 1550px;
}
.event_calanderview{
    background-color: transparent;
}
.calander-evnt{
    background-color: #f2f2f2;
    padding: 20px;
}
.fc.fc-media-screen.fc-theme-standard.fc-direction-ltr{
    height: 100%;
    padding-bottom: 40px;
}
.courseDetail_tab_wrap .course_tab_content .courseDetail{
    max-width: 1500px;
    width: 100%;
}
.courseDetail_tab_wrap .course_tab_content .courseDetail .event_calanderview >div{
    display: flex;
    gap: 30px;
}
.courseDetail_tab_wrap .course_tab_content .courseDetail .event_calanderview .calander-evnt{
    max-width: 75%;
    width: 100%;
}
.calander-event-side {
    max-width: 24.2%;
    width: 100%;
    background-color: #f2f2f2;
    padding: 25px 20px 40px;
}
.calander-event-side{
    display: flex;
    flex-direction: column;
    h3{
        font-size: 24px;
        margin-bottom: 20px;
    }
    .no-event-select{
        text-align: center;
    }
    ul{
        padding: 20px;
        li{
            margin-bottom: 20px;
            display: flex;
                align-items: start;
                font-size: 16px;
                img{
                    max-width: 20px;
                    margin-right: 8px;
                }
            a{
                display: flex;
                align-items: start;
                font-size: 16px;
                img{
                    max-width: 20px;
                    margin-right: 8px;
                }
            }
        }
    }
    .btn-event{
        display: flex;
        flex: 1;
        height: auto;
        align-items: end;
        gap: 10px;
        justify-content: space-evenly;
        .btn{
            display: inline-block;
            height: fit-content;
            background-color: #494949;
            color: #fff;
            font-weight: normal;
            text-transform: capitalize;
            &:hover{
                background-color: #f7ce46;
                color: #494949;
            }
        }
         .dropdown{
            display: inline-block;
            height: fit-content;
            background-color: #494949;
            color: #fff;
            font-weight: normal;
            text-transform: capitalizex;
            border-radius: 0.5rem;
            line-height: 2.5rem; 
            border: none;
            &:hover{
                background-color: #f7ce46;
                color: #494949;
                outline: none
            }
        }
    }
}
.courseDetail {
    &.dropdown {
      position: relative;
      display: inline-block;
  
      .dropdown-content {
        max-height: 0;
        overflow: hidden;
        transition: 0.7s;
  
        &.open {
            max-height: 9999px;  
        }
  
        p {
          margin: 0;
          padding: 10px;
        }
      }
  
      .courseDetail_title {
        padding: 10px;
        cursor: pointer;
        .arrow_img{
           
        }
      }
    }
  }
  .resetpassword .form_group .btn.btn_gray{
    margin-right: 15px;
  }
  .reset_button .btn.btn_gray{
    margin-right: 15px;
  }