.btn-loader {
    .ant-spin-dot-item {
        background-color: $yellow;
    }
}

.btn {
    @extend .fs_15;
    line-height: 1.8rem;
    @extend .text_center;
    @extend .border_0;
    @extend .radius_05;
    @extend .cursor_pointer;
    @extend .text_uppercase;
    @extend .fw_600;
    color: $black;
    padding: 0.85rem 1.5rem;
    min-width: 8.7rem;
    background: $gray;
    @include transition(all .3s ease-in-out);

    &:hover {
        background: $black;
        color: $white;
    }
}

.btn_light {
    @extend .fs_15;
    line-height: 1.8rem;
    @extend .text_center;
    @extend .border_0;
    @extend .radius_05;
    @extend .cursor_pointer;
    @extend .text_uppercase;
    @extend .fw_600;
    color: $black;
    padding: 0.85rem 1.5rem;
    min-width: 12.3rem;
    background: $thik_gray;
    @include transition(all .3s ease-in-out);

    &:hover {
        background: $black;
        color: $white;
    }
}

.btn_primary {
    @extend .fs_15;
    line-height: 1.8rem;
    @extend .text_center;
    @extend .border_0;
    @extend .radius_05;
    @extend .cursor_pointer;
    @extend .text_uppercase;
    @extend .fw_600;
    color: $black;
    padding: 0.85rem 1.5rem;
    min-width: 10.2rem;
    background: $yellow;
    @include transition(all .3s ease-in-out);

    &:hover {
        background: $black;
        color: $white;
    }
    &.undefined{
        background: $black;
    }
}

.save_later_btn {
    @extend .fs_15;
    line-height: 1.8rem;
    @extend .text_center;
    @extend .border_0;
    @extend .radius_05;
    @extend .cursor_pointer;
    @extend .text_uppercase;
    @extend .fw_600;
    color: $black;
    padding: 0.85rem 1.5rem;
    min-width: 10.2rem;
    background: #F7CE46;
    @include transition(all .3s ease-in-out);

    &:hover {
        background: $black;
        color: $white;
    }
}

.btn_primary_app {
    min-width: 177px;
    justify-content: center;
  }

.yellow_btn {
    background: $yellow;
    border: 0;
    height: 30px;
    width: 100%;
    display: block;
    color: $maroon;
    @extend .national_semibold;
    font-size: 12px;
    line-height: 30px;
    @extend .text_capitalize;
    @extend .text_center;
    border-radius: 3px;
    @include transition(all .3s ease-in-out);

    &:hover {
        background: $black;
        color: $white;
    }
}

.disabled_yellow_btn {
    background: #999999;
    border: 0;
    height: 30px;
    width: 100%;
    display: block;
    color: #676767;
    @extend .national_semibold;
    font-size: 12px;
    line-height: 30px;
    @extend .text_capitalize;
    @extend .text_center;
    border-radius: 3px;
    @include transition(all .3s ease-in-out);

    &:hover {
        background: #999999;
        color: #676767;
    }
}