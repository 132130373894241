footer {
  width: 100%;
  bottom: 0;

  .footer {
    width: 100%;
    min-height: 9rem;
    background: #191919;
    padding-top: 8.6rem;
    padding-bottom: 2.7rem;

    @media (max-width: $phone_landscape) {
      padding: 18px 0;
      min-height: auto;
    }

    .container {
      max-width: 110rem;
    }

    &_content {
      // max-width: 94rem;
      @extend .d_flex;
      @extend .align_items_center;
      @extend .justify_content_around;

      @media screen and (max-width: $phone_landscape) {
        flex-direction: column;
        justify-content: center;
      }
    }

    &_logo {
      // width: calc(33.33% - 10px);
      // width: 12rem;

      @media (max-width: $phone_landscape) {
        margin: 2rem 0;
      }

      img {
        @extend .img_object_center;
        @extend .cursor_pointer;
      }
    }

    .copy_right {
      @extend .fs_15;
      line-height: 1.8rem;
      color: #cbcbcb;
      text-align: center;
      // margin-top: 1rem;

      @media screen and (max-width: $ipad_landscape){
        margin-bottom: 10px;
      }
      @media screen and (max-width: $phone_landscape) {
        max-width: 31rem;
        text-align: center;
        margin-top: 0;
        margin-bottom: 8px;
      }

      span {
        @media screen and (max-width: $phone_landscape) {
          display: none;
        }
      }

      a {
        &:hover {
          color: $maroon;
        }
      }
    }
  }
}

.replace_logo {
  .footer_content {
    .footer_logo {
      img {
        width: 100%;
      }
    }
    .copy_right {
      margin-top: 0.5rem;
    }
  }
}

footer {
  .footer {
    .container {
      max-width: 132rem;
      .footer_content {
        justify-content: space-between;
        @media screen and (max-width: $ipad_landscape){
          flex-direction: column;
        }
        .footer_social {
          @media screen and (max-width: $ipad_landscape){
            margin-bottom: 18px;
          }
          @media screen and (max-width: $phone){
            margin-bottom: 0;
          }
          ul {
            @extend .d_flex_center;
            li {
              margin: 0 0.8rem;
              a {
                @extend .d_flex_center;
                @extend .radius_rounded;
                height: 2.8rem;
                width: 2.8rem;
                transition: all 0.3s ease-in-out;
                svg {
                  &:hover {
                    path {
                      fill: #f7ce46;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .policy_tems {
        @extend .d_flex_center;
        @media screen and (max-width: $ipad_landscape){
          flex-direction: column;
        }

        a {
          display: block;
          color: #cbcbcb;
          &:hover {
            color: #f7ce46;
          }
        }
        .line_gap {
          display: inline-block;
          color: #666;
          margin: 0 0.5rem;
          @media screen and (max-width: $ipad_landscape){
            display: none;
          }
        }
      }
    }
  }
}
