@font-face {
    font-family: national_regular;
    src: url(../fonts/national-regular.otf);
}
@font-face {
    font-family: national_semibold;
    src: url(../fonts/national_semibold.otf);
}
@font-face {
    font-family: national_light;
    src: url(../fonts/national-light.ttf);
}

.national_regular{font-family: national_regular;}
.national_semibold{font-family: national_semibold;}
.national_light{font-family: national_light;}


@font-face {
    font-family: 'Gabriel Weiss\' Friends Font';
    src: url('../fonts/Gabriel/GabrielWeissFriendsFont.eot');
    src: url('../fonts/Gabriel/GabrielWeissFriendsFont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gabriel/GabrielWeissFriendsFont.woff2') format('woff2'),
        url('../fonts/Gabriel/GabrielWeissFriendsFont.woff') format('woff'),
        url('../fonts/Gabriel/GabrielWeissFriendsFont.ttf') format('truetype'),
        url('../fonts/Gabriel/GabrielWeissFriendsFont.svg#GabrielWeissFriendsFont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.gabriel_regular{font-family: 'Gabriel Weiss\' Friends Font';}




@font-face {
    font-family: 'Avenir LT Std';
    src: url('../fonts/AvenirLTStd-Roman.eot');
    src: url('../fonts/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirLTStd-Roman.woff2') format('woff2'),
        url('../fonts/AvenirLTStd-Roman.woff') format('woff'),
        url('../fonts/AvenirLTStd-Roman.ttf') format('truetype'),
        url('../fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Kaisei Opti';
//     src: url('../fonts/kaisai/KaiseiOpti-Regular.eot');
//     src: url('../fonts/kaisai/KaiseiOpti-Regular.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/kaisai/KaiseiOpti-Regular.woff2') format('woff2'),
//         url('../fonts/kaisai/KaiseiOpti-Regular.woff') format('woff'),
//         url('../fonts/kaisai/KaiseiOpti-Regular.ttf') format('truetype'),
//         url('../fonts/kaisai/KaiseiOpti-Regular.svg#KaiseiOpti-Regular') format('svg');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Kaisei Opti';
//     src: url('../fonts/kaisai/KaiseiOpti-Bold.eot');
//     src: url('../fonts/kaisai/KaiseiOpti-Bold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/kaisai/KaiseiOpti-Bold.woff2') format('woff2'),
//         url('../fonts/kaisai/KaiseiOpti-Bold.woff') format('woff'),
//         url('../fonts/kaisai/KaiseiOpti-Bold.ttf') format('truetype'),
//         url('../fonts/kaisai/KaiseiOpti-Bold.svg#KaiseiOpti-Bold') format('svg');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }


.avenir_font{font-family: 'Avenir LT Std';}
